.cms-home {
    .page-title-wrapper {
        display: none;
    }
}
.block-title {
    font-size: $font-size__xl;
    text-transform: uppercase;
    font-weight: $font-weight__bold;
    width: 100%;
    text-align: center;
}
.full-width-container {
    @include fullpage-width;
    &.hero-container {
        display: flex;
        flex-direction: row;
    }
}
.title-47-bold {
    //font-size: 47px;
    font-size: 2.5vw;
    font-weight: $font-weight__bold;
    @media (min-width: $layout__max-width) {
        font-size: 47px;
    }
    &--upp {
        text-transform: uppercase;
    }
    &--white {
        color: $color-white;
        &.background{
            span {
                background: $color-black;
            }
        }
    }
    &,
    .background{
        &.left{
            span{
                padding-left: 5px;
                @media (max-width: $screen__s){
                    padding-right: 5px;
                }
            }
        }
        &.right{
            span {
                padding-right: 5px;
                @media (max-width: $screen__s){
                    padding-left: 5px;
                }
            }
        }
        span{
            padding-right: 5px;
            padding-left: 5px;
            background: $color-white;
        }
    }
}

.title-30-bold {
    //font-size: 47px;
    font-size: 1.6vw;
    font-weight: $font-weight__bold;
    @media (min-width: $layout__max-width) {
        font-size: 30px;
    }
    @media (max-width: $screen__m) {
        font-size: 20px;
    }
    &--upp {
        text-transform: uppercase;
    }
    &--white {
        color: $color-white;
    }
}

a.link {
    font-size: $font-size__s;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $color-black;
    &:hover {
        text-decoration: underline;
    }
}
.button, .action {
    &.primary {
        background: $color-black;
        font-size: $font-size__s;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $color-white;
        padding: 20px;
        border: 1px solid $color-black;
        max-width: 245px;
        line-height: 2.4rem !important;
        &:hover, &:visited, &:active {
            background: $color-white;
            border: 1px solid $color-black;
            color: $color-black;
        }
    }
    &.secondary {
        font-size: $font-size__s;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $color-black;
        border: 1px solid $color-black;
        padding: 20px;
        max-width: 245px;
        line-height: 2.4rem !important;
        &:hover, &:active {
            background-color: $color-black;
            color: $color-white;
        }
    }
}
.double-banner {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 889px;
    width: 50%;
    transition: height 300ms ease-out;
    > img {
        display: none;
    }
    .text-container {
        position: absolute;
        top: 57%;
        transform: translateY(-50%);
        overflow: hidden;
        width: 100%;
        //max-width: 210px;
        max-width: 330px;
        min-height: 255px;
        .title-47-bold {
            position: absolute;
            top: 0;
            line-height: 1.3;
            font-size: 47px;
        }
        .list {
            //padding-top: 120px;
            padding-top: 155px;
            ul {
                padding-left: 0;
                list-style: none;
                li a {
                    color: $color-white;
                    font-size: $font-size__s;
                    text-transform: uppercase;
                    background: $color-black;
                    padding: 3px;
                }
                .red {
                    color: #ff0000;
                    font-size: $font-size__s;
                    text-transform: uppercase;
                }
            }
        }
    }
    &.left {
        .img-layer {
            height: 100%;
            width: 100%;
            background-image: linear-gradient(to right, rgba(81, 84, 89, 0), rgba(79, 83, 87, 0.04) 64%, rgba(40, 42, 45, 0));
        }
        .text-container {
            right: 0;
            //top: 57%;
            top: 55.6%;
            text-align: right;
            margin-right: 20px;
            .title-47-bold {
                //right: -8px;
            }
        }
    }
    &.right {
        //background-position: 80% center;
        .img-layer {
            width: 50%;
            height: 100%;
            //background-image: linear-gradient(to left, rgba(81, 84, 89, 0), rgba(79, 83, 87, 0.0) 14%, #e0e0e0);
            background-image: linear-gradient(to left, rgba(81, 84, 89, 0), rgba(81, 84, 89, 0), rgba(81, 84, 89, 0) , rgba(79, 83, 87, 0) 14%, #e0e0e0);
        }
        .text-container {
            left: 0;
            text-align: left;
            margin-left: 20px;
            .title-47-bold {
                //left: -8px;
            }
            .list {
                padding-top: 155px;
                ul li a {
                    color: $color-black;
                    background: $color-white;
                    padding: 3px;
                }
            }
        }
    }
}
.registration-form {
    margin-top: 5%;
    .title {
        //max-width: 608px;
        text-align: center;
        margin: auto;
    }
    .subtitle {
        font-size: $font-size__base;
        margin-top: 20px;
        margin-bottom: 30px;
        text-align: center;
    }
    .button-container {
        max-width: 608px;
        margin: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
    &.bnw {
        display: flex;
        flex-direction: row;
        margin-bottom: 2%;
        margin-top: 2%;
        justify-content: space-around;
        .item {
            //max-height: 423px;
            max-height: 315px;
            //height: 41vh;
            flex-basis: 42%;
            text-align: center;
            position: relative;
            padding: 0 10px;
            .title-47-bold {
                //margin-top: 9%;
                margin-top: 5%;
                margin-bottom: 13%;
                //position: absolute;
                left: 50%;
                //transform: translateX(-50%);
                //width: 100%;
                font-size: 47px;
                word-break: break-all;
            }
            .button {
                //position: absolute;
                //left: 50%;
                //transform: translateX(-50%);
                //top: 46%;
                display: block;
                width: fit-content;
                margin: auto;
                margin-bottom: 24%;
            }
            &.black {
                background-color: $color-black;
                .button.secondary {
                    color: $color-white;
                    border: 1px solid $color-white;
                    margin: auto;
                    //margin-bottom: 24%;
                    margin-bottom: 0;
                }
            }
        }
    }
    .inverted {
        .button.secondary {
            color: $color-white;
            background: $color-black;
            &:hover,&:active {
                color: $color-black;
                background: $color-white;
            }
        }
    }
}
.filter-register-login{
    padding-left: $layout-indent__width;
    .registration-form{
        margin-top: 28.5px;
        flex-direction: column;

        .item{
            flex-basis: unset;
            //max-height: 230px;
            max-height: 185px;
            height: 100vh;
            border: 1px solid $color-black;
            box-sizing: border-box;
            padding: 0 5px;

            .title-47-bold{
                width: 100%;
                margin-top: 20px;
                font-size: 18px;
                word-break: break-word;
            }

            .button{
                font-size: 12px;
            }
        }
    }
}
.filter-value-proposition {
    padding-left: 42px;
    .block-title {
        text-align: left;
    }
    .value-proposition {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        .item {
            text-align: left;
            margin-bottom: 20px;
            img {
                margin: 0;
            }
        }
    }
}
.img-blk {
    max-width: 645px;
    transition: all 300ms ease-out;
}
.full-width-container {
    .img-blk {
        width: 34%;
    }
}
.sec-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15%;
    margin-top: 8%;
    flex-direction: row-reverse;
    .sec-right {
        top: -23%;
        width: 35%;
        //margin-right: 20px;
        background: transparent;
    }
    .text {
        max-width: 500px;
        width: 28%;
        text-align: justify;
        align-self: flex-end;
        margin-right: 25px;
        margin-top: 40%;
        .title {
            //font-size: $font-size__xl;
            font-size: 43px;
            font-weight: $font-weight__bold;
            line-height: 1.31;
            margin-bottom: 20px;
            text-align: left;
        }
        .content {
            line-height: 2;
            font-size: $font-size__base__res;
            @media (min-width: $layout__max-width) {
                font-size: $font-size__base;
            }
        }
    }
}
.sec {
    position: absolute;
    width: 35%;
    max-width: 645px;
    transition: width 300ms ease-out;
    .title-47-bold {
        position: absolute;
        bottom: 6%;
        width: 100%;
        text-align: center;
        > a {
            color: $color-white;
        }
    }
    img {
        width: 100%;
    }
    &-left {
        //margin-top: 14%;
        margin-top: 7%;
        margin-left: 20px;
        //position: relative;
        display: inline-block;
        left: 0;
    }
    &-center {
        top: 30%;
        left: 50%;
        transform: translateX(-50%);
    }
}
.sec-right {
    position: absolute;
    right: 0;
    top: 8%;
    //width: 1336px;
    width: 69.5%;
    background: $color-white;
    transition: top 300ms linear;
    .block-title {
        margin-top: 5.5%;
        //margin-bottom: 6.5%;
        margin-bottom: 3.5%;
    }
    .content {
        margin-bottom: 5.5%;
        display: grid;
        grid-template-columns: repeat(2, auto);
        justify-content: space-evenly;
        .item {
            //max-width: 300px;
            max-width: 500px;
            //width: 16vw;
            width: 25vw;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            img {
                margin-right: 20px;
                max-height: 22px;
                width: auto;
            }
            .item-title {
                font-size: $font-size__base;
                text-transform: uppercase;
                font-weight: $font-weight__bold;
                margin-bottom: 14px;
            }
        }
    }
    .registration-form {
        position: absolute;
        right: 19.5%;
        width: 69.5%;
        .button-container {
            justify-content: center;
            a.link {
                margin-right: 67px;
            }
        }
    }
    &.vp {
        .content .item img {
            min-width: 13%;
        }
    }
}
.two-blocks-container{
    display: flex;
    flex-wrap: wrap;

    .block-item{
        width: 50%;
        position: relative;

        &.width-40{
            width: 40%;
        }

        &.width-100{
            width: 100%;
        }

        &.pos-absolute{
            position: absolute;
            top: 110px;
            left: 0;
        }

        &.index-up{
            z-index: 1;
        }

        .margin-10-right{
            margin-right: 10%;
        }

        .margin-10-left{
            margin-left: 10%;
        }

        .margin-30-left{
            margin-left: 30%;

            .block-item-img{
              margin-bottom: 0px;
            }
        }

        .block-item-img{
            width: 100%;
            height: auto;
            object-fit: contain;
        }

        .block-item-text-container{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            &.right-align{
                left: 75%;
            }

            &.left-aligh{
                left: 30%;
            }

            .flexed-text{
                display: flex;

                &.flex-col{
                    flex-direction: column;
                }
            }

        }
    }
}

.seasons-banner-container{
    background-color: $color-white;
    padding: 60px 0;
    width: 100%;
    max-width: $layout__max-width;
    position: relative;

    &.second{
        background-color: $color-dark-gray;
    }

    .margin-10-right, .margin-30-left{
        position: relative;
    }

    .block-item-img{
        margin-bottom: 25px;
    }

    &.width-100{
        .block-item-img{
            margin-bottom: 0px;
        }
    }

    .button.secondary{
        margin: 60px auto;
        background-color: $color-white;
        &:hover, &:active {
            background-color: $color-black;
        }
    }
}
.slider-title-container{
  max-width: 1440px;
  width: 100%;
  margin: 40px auto;

  &.title-left{
    text-align: left;
  }

  &.title-center{
    text-align: center;
  }

  .slider-title, .slider-subtitle{
    font-size: 27px;
    font-weight: $font-weight__bold;

    &.title-grey{
      color: $color-second-season-inactive;
    }

    &.title-upp{
      text-transform: uppercase;
    }
  }
}


// Fix per slider brand in landing second-season
.category-second-season, .category-season {
    .block-brand-feature{
        .owl-carousel{
            .owl-nav{
                .owl-prev{
                    left: 0px;
                }
                .owl-next{
                    right: 0px;
                }
            }
        }
    }

    .category-no-image-container{
        display: none;
    }

    .cosmo-products-slider{
        max-width: $layout__max-width;
        margin-bottom: 6% !important;

        .products-grid {

            .product-items {
                display: block;
                width: 100%;
                max-width: $layout__max-width;

                &.owl-carousel{
                    .owl-item {
                        img{
                            height: auto;
                        }
                    }

                    .owl-nav{
                        top: 30%;
                    }

                    .owl-prev {
                        background-size: 26px 26px !important;
                        background-repeat: no-repeat !important;
                        background-position: center;
                        width: 100px;
                        height: 100px;
                        left: 20%;
                        background-color: $color-white;
                        border: 1px solid $color-black;
                        border-radius: 0;
                    }
                    .owl-next {
                        background-size: 26px 26px !important;
                        background-repeat: no-repeat !important;
                        background-position: center;
                        width: 100px;
                        height: 100px;
                        right: 20%;
                        background-color: $color-white;
                        border: 1px solid $color-black;
                        border-radius: 0;
                    }
                }
            }

            .product-item{
                display: inline-block;
                width: 100%;
                margin: 0;
                padding: 0 15px;
                box-sizing: border-box;

            }
        }
    }
  .block-brand-feature{
    margin-bottom: 6%;
  }
}
@media (max-width: $screen__xl) {
    .full-width-container {
        .sec-right {
            right: 7.5%;
            width: 65.5%;
            .block-title {
                margin-top: 6.5%;
                margin-bottom: 4.5%;
            }
            .content {
                margin-bottom: 6.5%;
                .item {
                    //width: 22vw;
                    width: 31vw;
                    .text-container {
                        font-size: $font-size__s;
                    }
                }
            }
            .registration-form {
                margin-top: 3%;
            }
        }
        .img-blk {
            width: 29%;
        }
    }
    .sec-container {
        .sec {
            width: 30%;
            &-left {
                margin-left: 6.5%;
            }
            &-right {
                margin-right: 6.5%;
                //top: -21%;
                top: -18%
            }
        }
        .text {
            margin-right: 6.5%;
            //.content {
            //    font-size: $font-size__s;
            //}
            .title {
                font-size: $font-size__xl__res;
            }
        }
    }
}
@media (max-width: $screen__l) {
    .full-width-container {
        .sec-right {
            .block-title {
                font-size: $font-size__l;
                margin-left: 10px;
                margin-right: 10px;
                width: unset;
            }
        }
    }
    .sec-container {
        flex-direction: column;
        margin-bottom: 6%;
        margin-top: 11%;
        .text {
            width: 60%;
            max-width: 100%;
            margin-left: 6.5%;
            //margin-top: 10%;
            margin-top: 18%;
            align-self: center;
            //font-size: $font-size__s;
            .content {
                font-size: $font-size__s;
            }
        }
        .sec {
            &-left {
                position: relative;
            }
            &-right {
                top: 0%;
            }
            &-center {
                top: 22%;
            }
        }
    }
    .category-second-season, .category-season {
        .breadcrumbs {
            padding-left: $layout__width-xs-indent;
        }
    }
}
@media (max-width: $screen__m) {
    .double-banner {
        height: 400px;
        .text-container {
            min-height: 200px;
        }
        &.left {
            .text-container {
                top: 63%;
                max-width: 200px;
                .title-47-bold {
                    right: -4px;
                    font-size: $font-size__xl;
                }
                .list {
                    //padding-top: 60px;
                    padding-top: 76px;
                }
            }
        }
        &.right {
            .text-container {
                top: 63%;
                max-width: 200px;
                .title-47-bold {
                    left: -4px;
                    font-size: $font-size__xl;
                }
                .list {
                    padding-top: 76px;
                }
            }
        }
    }
    .title {
        &-47-bold {
            font-size: $font-size__xl;
        }
    }
    .full-width-container {
        img {
            display: none;
        }
        .sec-right {
            position: static;
            width: 100%;
            .content {
                //grid-template-columns: auto;
                .item {
                    width: 230px;
                    img {
                        display: block;
                    }
                }
            }
            .registration-form {
                position: static;
                width: 100%;
                margin-bottom: 6.5%;
                margin-top: 60px;
            }
        }
    }
    .sec-container {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        margin-top: 60px;
        .sec {
            position: relative;
            width: 100%;
            max-width: 280px;
            max-height: 417px;
            margin: 0;
            //margin: 0;
            //width: 100%;
            //max-width: unset;
            //text-align: center;
            &-center {
                margin-top: 7%;
                transform: none;
                left: 0;
                transition: left 300ms ease-out;
            }
            &-right {
                margin-top: 7%;
            }
        }
        .text {
            .title {
                font-size: $font-size__xl;
            }
            .content {
                font-size: $font-size__base;
            }
        }
    }
    .registration-form.bnw .item {
        //height: 24vh;
        height: 220px;
        flex-basis: 50%;
        .title-47-bold {
            top: 15%;
            //margin-top: 0;
            font-size: $font-size__xl;
        }
    }

    .two-blocks-container{

        .block-item{
            width: 100%;
            height: 350px;
            @include transition(height);

            .block-item-img{
                display: block;
                width: auto;
                height: 350px;
                object-fit: cover;
                position: absolute;
                @include transition(height);
            }
        }

        &.seasons-banner-container{
            display: flex;
            flex-direction: column;
            padding: 0;

            .block-item-img{
                width: auto;
                height: 100%;
                position: relative;
            }

            .width-100{
                order: 1;
                margin-left: 0;
                height: 690px;
                @include transition(height);
            }

            .width-40{
                order: 2;
                width: 100%;
                position: relative;
                top: unset;
                left: unset;
                height: 1170px;
                @include transition(height);
            }

            .margin-10-left, .margin-10-right{
                margin-left: 0;
                margin-right: 0;
                height: 390px;
                @include transition(height);
            }

            .margin-30-left{
                margin-left: 0;
                margin-right: 0;
                height: 690px;
                @include transition(height);
            }


        }
    }

    .category-second-season, .category-season {
        .breadcrumbs {
            margin-left: 0;
            margin-top: -9px;
        }

        .slider-title, .slider-subtitle{
            padding: 0 15px;
        }

        .cosmo-products-slider{

            .products-grid {

                .product-items {

                    &.owl-carousel{

                        .owl-nav{
                            top: 30%;
                        }

                        .owl-prev {
                            background-size: 26px 26px !important;
                            background-repeat: no-repeat !important;
                            background-position: center;
                            width: 60px;
                            height: 60px;
                            left: 10%;
                            background-color: $color-white;
                            border: 1px solid $color-black;
                            border-radius: 0;
                        }
                        .owl-next {
                            background-size: 26px 26px !important;
                            background-repeat: no-repeat !important;
                            background-position: center;
                            width: 60px;
                            height: 60px;
                            right: 10%;
                            background-color: $color-white;
                            border: 1px solid $color-black;
                            border-radius: 0;
                        }
                    }
                }
            }
        }
    }

}
@media (max-width: $screen__s) {
    .full-width-container .sec-right {
        .block-title {
            max-width: 245px;
            margin: auto;
            margin-bottom: 40px;
        }
        .content {
            grid-template-columns: auto;
            row-gap: 40px;
            .item:last-child {
                //img {
                //    margin-right: 32px;
                //}
            }
        }
    }
    .full-width-container.hero-container {
        flex-wrap: wrap;
    }
    .double-banner {
        width: 100%;
        max-height: 288px;
        .img-layer {
            position: absolute;
        }
        .text-container {
            position: static;
            text-align: center !important;
            transform: none;
            margin: auto !important;
            min-height: 263px;
            .title-47-bold {
                position: static;
                margin-top: 30px;
            }
            .list {
                padding-top: 0;
            }
        }
    }
    .title-47-bold {
        font-size: $font-size__l;
    }
    .sec-right .registration-form .button-container a.link {
        margin-right: 0;
    }
    .registration-form .button-container {
        flex-direction: column;
        a.link {
            margin-bottom: 27px;
        }
    }
    .sec-container {
        .text {
            margin-left: 0;
            margin-right: 0;
            width: 97%;
            .title {
                text-align: left;
            }
        }
    }
    .registration-form.bnw {
        flex-direction: column;
        @include fullpage-width;
        margin-bottom: 12%;
        .item {
            //min-height: 250px;
            min-height: 210px;
            max-height: 250px;
            flex-basis: 100%;
            .button {
                width: 100%;
                margin: auto;
            }
            &.black {
                .button {
                    width: fit-content;
                }
            }
        }
    }
    .two-blocks-container{

        .block-item{
            height: 270px;
            @include transition(height);

            .block-item-img{
                height: 270px;
                @include transition(height);
            }
        }

        &.seasons-banner-container{

            .block-item-img{
                height: 100%;
                @include transition(height);
            }

            .width-100{
                height: 445px;
                @include transition(height);
            }

            .width-40{
                height: 828px;
                @include transition(height);
            }

            .margin-10-left, .margin-10-right{
                height: 276px;
                @include transition(height);
            }

            .margin-30-left{
                height: 445px;
                @include transition(height);
            }


        }
    }
}

